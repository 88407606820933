/* src/App.css */
html, body, #root, .App {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

main.content {
  padding: 80px 20px 20px 20px; /* Top padding for navbar space, bottom padding for footer space */
}

@media (min-width: 768px) {
  .content {
    margin: 0 100px;
  }
}